const typed = () =>{
    var typed = new Typed("#typed", {
        strings: [
          '<span class="highlight">negócios</span>', 
          '<span class="highlight">conteúdos</span>',
          '<span class="highlight">inovações</span>'
        ],
        typeSpeed: 30,
        backSpeed: 30,
        backDelay: 3000,
        startDelay: 1500,
        showCursor: true,
        loop: true,
        cursorChar: '_',
      });
}

export default typed;