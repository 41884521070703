const SwiperPartners = () =>{
    function main(){
        const classSwiper = "swiper-partners";

        var swiper = new Swiper(`.${classSwiper}`, {
            slidesPerView: "auto",
            spaceBetween: 24,
            loop: true,
            autoplay: {
                delay: 0,
            },
            speed: 2000,
          });
    }
    main();
}

export default SwiperPartners;