// PAGES
//const pageHome = document.querySelector(".body-home");
const pageCommingSoon = document.querySelector("#page__comming_soon");
const pageHome = document.querySelector("#page__home");

// SCRIPT SENHA LOGIN
import mostrarSenha from "./mostrarSenha.js"
import showFormErrors from "./showFormErrors.js";

mostrarSenha()
showFormErrors()

// SECTIONS
/*import salebrandPortfolio from "./salebrand/salebrandPortfolio.js";
import salebrandSobre from "./salebrand/salebrandSobre.js";
import salebrandClientes from "./salebrand/salebrandClientes.js";
import salebrandContato from "./salebrand/salebrandContato.js";

salebrandPortfolio();
salebrandSobre();
salebrandClientes();
salebrandContato();*/

import SwiperPartners from "./swiper/swiper-partners.js";

import typed from "./animations/typed.js";

// ★ Pages Scopo
if(pageCommingSoon){
  document.body.classList.add("body__comming_soon");
}

if(pageHome){
  document.body.classList.add("body__home");

  SwiperPartners();
  typed();
}

document.addEventListener("DOMContentLoaded", () =>
  document.body.classList.add("dcl")
);
